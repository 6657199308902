import * as React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

const useStyles = (props) =>
    makeStyles((theme) => ({
        mainTitle: {
            fontSize: '2.25rem',
            fontWeight: '700',
            paddingBottom: '30px',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        title: {
            fontSize: '1.75rem',
            fontWeight: '600',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        text: {
            fontSize: '1.5rem',
            fontWeight: '400',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
    }))

const SAccordion = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasTitle = Boolean(data.title)

    return (
        <Box py={10}>
            <Container maxWidth="lg">
                {hasTitle && (
                    <Typography className={classes.mainTitle}>
                        {data.title}
                    </Typography>
                )}

                {data.accordionTab.map((index) => {
                    const hasTitle = Boolean(index.title)
                    const hasText = Boolean(index.text)

                    return (
                        <>
                            <Accordion style={{ boxShadow: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {hasTitle && (
                                        <Typography className={classes.title}>
                                            {index.title}
                                        </Typography>
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {hasText && (
                                        <Typography className={classes.text}>
                                            {renderRichText(
                                                index.text,
                                                options()
                                            )}
                                        </Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                })}
            </Container>
        </Box>
    )
}

export default SAccordion
