import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = (props) =>
    makeStyles((theme) => ({
        container: {
            paddingTop: '5rem',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '3rem',
            },
        },
        mainTitle: {
            fontSize: '2.25rem',
            fontWeight: '700',
            fontFamily: 'IBM Plex Mono',
            paddingBottom: '4rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                paddingBottom: '2rem',
            },
        },
        number: {
            backgroundColor: '#F8F7F7',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '24px',
                height: '24px',
                fontSize: '0.875rem',
            },
        },
        title: {
            fontSize: '1.75rem',
            fontWeight: '600',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        subtitle: {
            fontSize: '1.125rem',
            fontWeight: '700',
            color: '#75CCBD',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        text: {
            fontSize: '1.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        iconText: {
            fontSize: '1.125rem',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        box1: {
            marginTop: '120px',
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '20px 80px 20px 80px',
            width: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0px',
                marginLeft: 0,
                marginRight: 0,
                width: '100%',
                padding: '20px 10px 20px 10px',
            },
        },
        box2: {
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '20px 80px 20px 80px',
            width: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginTop: '5px',
                marginLeft: 0,
                marginRight: 0,
                width: '100%',
                padding: '20px 10px 20px 10px',
            },
        },
        box3: {
            marginTop: '80px',
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '20px 80px 20px 80px',
            width: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginTop: '5px',
                marginLeft: 0,
                marginRight: 0,
                width: '100%',
                padding: '20px 10px 20px 10px',
            },
        },
        textTestimonial: {
            fontSize: '1.25rem',
            fontWeight: 400,
            lineHeight: '34px',
            color: '#0F051899',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '18px',
                fontWeight: 400,
            },
        },
        titleBox: {
            padding: '10px 30px 10px 30px',
            width: '500px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        mainTitle: {
            fontSize: '2.25rem',
            fontWeight: 700,
            lineHeight: '56px',
            color: '#0F0518',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '24px',
                fontWeight: 600,
            },
        },
        mainSubtitle: {
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: '38px',
            color: '#0F051899',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '18px',
                fontWeight: 400,
            },
        },
        name: {
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: '38px',
            color: '#0F0518',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '24px',
                fontWeight: 600,
            },
        },
        testimonialWrapper1: {
            minHeight: '1000px',
            [theme.breakpoints.down('sm')]: {
                minHeight: 0,
            },
        },
        header: {
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: '23px',
            color: '#75CCBD',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '18px',
                fontWeight: 500,
            },
        },
        image2Testimonial: {
            [theme.breakpoints.down('sm')]: {
                width: '96px',
                height: '16px',
            },
        },
        quoteMarks: {
            position: 'absolute',
            top: '-45px',
            left: '13%',
            [theme.breakpoints.down('sm')]: {
                width: '55px',
                height: '48px',
                top: '-15px',
                left: '6%',
            },
        },
    }))

const ContainerBox = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasTitle = Boolean(data.title)

    const chooseLayout = (input) => {
        switch (input) {
            default:
                return (
                    <Box className={classes.container}>
                        <Container maxWidth="lg">
                            {hasTitle && (
                                <Typography className={classes.mainTitle}>
                                    {data.title}
                                </Typography>
                            )}
                            <Grid container>
                                {data.boxes.map((input) => {
                                    const hasTitle = Boolean(input.title)
                                    const hasNumber = Boolean(input.number)
                                    const hasSubtitle = Boolean(input.subtitle)
                                    const hasText = Boolean(input.text)

                                    const icon1 = getImage(input.icon1)
                                    const icon2 = getImage(input.icon2)

                                    const hasIcon1 = Boolean(input.icon1)
                                    const hasIcon2 = Boolean(input.icon2)
                                    const hasText1 = Boolean(input.text1)
                                    const hasText2 = Boolean(input.text2)

                                    return (
                                        <Grid item container xs={12} md={6}>
                                            <Grid item xs={1}>
                                                {hasNumber && (
                                                    <Typography
                                                        className={
                                                            classes.number
                                                        }
                                                    >
                                                        {input.number}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={11}>
                                                {hasTitle && (
                                                    <Typography
                                                        className={
                                                            classes.title
                                                        }
                                                    >
                                                        {input.title}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={11}>
                                                {hasSubtitle && (
                                                    <Typography
                                                        className={
                                                            classes.subtitle
                                                        }
                                                    >
                                                        {input.subtitle}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={11}>
                                                {hasText && (
                                                    <Typography
                                                        className={classes.text}
                                                    >
                                                        {renderRichText(
                                                            input.text,
                                                            options()
                                                        )}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="left"
                                                xs={11}
                                                style={{
                                                    paddingBottom: '4rem',
                                                }}
                                            >
                                                <Grid item xs={1}>
                                                    {' '}
                                                    {hasIcon1 && (
                                                        <GatsbyImage
                                                            image={icon1}
                                                            alt={
                                                                input.icon1
                                                                    .title
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {' '}
                                                    {hasText1 && (
                                                        <Typography
                                                            className={
                                                                classes.iconText
                                                            }
                                                        >
                                                            {input.text1}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {' '}
                                                    {hasIcon2 && (
                                                        <GatsbyImage
                                                            image={icon2}
                                                            alt={
                                                                input.icon2
                                                                    .title
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {hasText2 && (
                                                        <Typography
                                                            className={
                                                                classes.iconText
                                                            }
                                                        >
                                                            {input.text2}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Container>
                    </Box>
                )
            case 'Testimonials':
                const hasTextimonialText = Boolean(data.text)

                const icon1 = getImage(
                    data && data.boxes[0] && data.boxes[0].icon1
                )
                const icon2 = getImage(
                    data && data.boxes[0] && data.boxes[0].icon2
                )

                const icon3 = getImage(
                    data && data.boxes[1] && data.boxes[1].icon1
                )
                const icon4 = getImage(
                    data && data.boxes[1] && data.boxes[1].icon2
                )

                const icon5 = getImage(
                    data && data.boxes[2] && data.boxes[2].icon1
                )
                const icon6 = getImage(
                    data && data.boxes[2] && data.boxes[2].icon2
                )

                const hasIcon1 = Boolean(
                    data && data.boxes[0] && data.boxes[0].icon1
                )
                const hasIcon2 = Boolean(
                    data && data.boxes[0] && data.boxes[0].icon2
                )
                const hasIcon3 = Boolean(
                    data && data.boxes[1] && data.boxes[1].icon1
                )
                const hasIcon4 = Boolean(
                    data && data.boxes[1] && data.boxes[1].icon2
                )
                const hasIcon5 = Boolean(
                    data && data.boxes[2] && data.boxes[2].icon1
                )
                const hasIcon6 = Boolean(
                    data && data.boxes[2] && data.boxes[2].icon2
                )

                return (
                    <Box
                        py={4}
                        className={classes.container}
                        style={{ backgroundColor: 'rgba(238, 233, 229, 0.5)' }}
                    >
                        <Box>
                            <Grid container style={{ position: 'relative' }}>
                                <Grid item xs={12}>
                                    <StaticImage
                                        src="../../assets/background_icons/q_m.png"
                                        alt="quoteMarks1"
                                        className={classes.quoteMarks}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Container maxWidth="lg">
                            <Grid container alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.testimonialWrapper1}
                                >
                                    <Box className={classes.titleBox}>
                                        <Typography className={classes.header}>
                                            {' '}
                                            {data.header}
                                        </Typography>
                                        {hasTitle && (
                                            <Typography
                                                className={classes.mainTitle}
                                            >
                                                {' '}
                                                {data.title}
                                            </Typography>
                                        )}
                                        {hasTextimonialText && (
                                            <Typography
                                                className={classes.mainSubtitle}
                                            >
                                                {renderRichText(
                                                    data.text,
                                                    options()
                                                )}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className={classes.box1}>
                                        <Grid container>
                                            <Grid xs={4}>
                                                {' '}
                                                {hasIcon1 && (
                                                    <GatsbyImage
                                                        image={icon1}
                                                        alt={
                                                            data.boxes[0].icon1
                                                                .title
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Grid xs={8}>
                                                {' '}
                                                <Typography
                                                    className={classes.name}
                                                >
                                                    {data.boxes[0].title}
                                                </Typography>
                                                {hasIcon2 && (
                                                    <Box
                                                        className={
                                                            classes.image2Testimonial
                                                        }
                                                    >
                                                        <GatsbyImage
                                                            image={icon2}
                                                            alt={
                                                                data.boxes[0]
                                                                    .icon2.title
                                                            }
                                                        />
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Typography
                                            className={classes.textTestimonial}
                                        >
                                            {renderRichText(
                                                data.boxes[0].text,
                                                options()
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <Box className={classes.box2}>
                                            <Grid container>
                                                <Grid xs={4}>
                                                    {' '}
                                                    {hasIcon3 && (
                                                        <GatsbyImage
                                                            image={icon3}
                                                            alt={
                                                                data.boxes[1]
                                                                    .icon1.title
                                                            }
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid xs={8}>
                                                    <Typography
                                                        className={classes.name}
                                                    >
                                                        {data.boxes[1].title}
                                                    </Typography>
                                                    {hasIcon4 && (
                                                        <Box
                                                            className={
                                                                classes.image2Testimonial
                                                            }
                                                        >
                                                            <GatsbyImage
                                                                image={icon4}
                                                                alt={
                                                                    data
                                                                        .boxes[1]
                                                                        .icon2
                                                                        .title
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                className={
                                                    classes.textTestimonial
                                                }
                                            >
                                                {renderRichText(
                                                    data.boxes[1].text,
                                                    options()
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className={classes.box3}>
                                            <Grid container>
                                                <Grid xs={4}>
                                                    {' '}
                                                    {hasIcon5 && (
                                                        <GatsbyImage
                                                            image={icon5}
                                                            alt={
                                                                data.boxes[2]
                                                                    .icon1.title
                                                            }
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid xs={8}>
                                                    <Typography
                                                        className={classes.name}
                                                    >
                                                        {data.boxes[2].title}
                                                    </Typography>
                                                    {hasIcon6 && (
                                                        <Box
                                                            className={
                                                                classes.image2Testimonial
                                                            }
                                                        >
                                                            <GatsbyImage
                                                                image={icon6}
                                                                alt={
                                                                    data
                                                                        .boxes[2]
                                                                        .icon2
                                                                        .title
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                className={
                                                    classes.textTestimonial
                                                }
                                            >
                                                {renderRichText(
                                                    data.boxes[2].text,
                                                    options()
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                )
        }
    }

    return <>{chooseLayout(data.layout)}</>
}

export default ContainerBox
