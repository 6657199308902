import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ButtonSelector from '../../components/ButtonSelector'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = (props) =>
    makeStyles((theme) => ({
        textGreen: {
            width: '20%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '50px 0px',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        subtitle: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1.5rem',
            lineHeight: '2.375rem',
            textAlign: 'center',
            fontFeatureSettings: 'liga off',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '1.125rem',
            },
        },
        title: {
            fontFamily: 'IBM Plex Mono',
            fontStyle: 'normal',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '3.375rem',
            lineHeight: '4.25rem',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.5rem',
            },
        },
        textOrange: {
            width: '20%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '50px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        buttonOutlined: {
            fontSize: '1.1875rem',
            fontWeight: 600,
            borderRadius: '0px',
            border: 'solid 4px #8BD2C1',
            '&:hover': {
                borderColor: theme.palette.primary.light,
            },
        },
        gray: {
            background: 'rgba(238, 233, 229, 0.5)',
        },
    }))

const CTAButton = (props) => {
    const data = props.data[props.componentId]

    const classes = useStyles({ ...props, data })()
    const hasTagClass = data.tagClass ? data.tagClass : ''

    const getTextColor = (color) =>
        color === 'Orange' ? classes.textOrange : classes.textGreen

    const getBackground = (input) => {
        return input === 'gray' ? classes.gray : ''
    }

    const hasBackground = getBackground(data.background)
    const text = getTextColor(data.buttonColor)
    return (
        <Box className={hasBackground} py={5}>
            <Container maxWidth="lg">
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.title}>
                        <Box pt={5}>{data.title}</Box>
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {data.subtitle}
                    </Typography>
                </Grid>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ButtonSelector
                        disableElevation
                        className={`${text} ${hasTagClass}`}
                        data={data}
                    />
                </Box>
            </Container>
        </Box>
    )
}

export default CTAButton
