import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = (props) =>
    makeStyles((theme) => ({
        title: {
            fontSize: '2.25rem',
            fontWeight: '700',
            fontFamily: 'IBM Plex Mono',
            textAlign: props.data.titleAlignment,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        text: {
            fontSize: '1.5rem',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        image: {
            width: '100px',
            height: '94px',
            [theme.breakpoints.down('sm')]: {
                width: '50px',
                height: '46px',
            },
        },
        secondaryTexts: {
            fontSize: '1.5rem',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
    }))

const RichText = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasTitle = Boolean(data.title)
    const hasSubtitle = Boolean(data.subtitle)
    const hasText = Boolean(data.text)
    const hasImageText = Boolean(data.imageText)

    const getBackgroundColor = (color) =>
        color === 'Green' ? 'background.green' : 'background.default'

    const bgcolor = getBackgroundColor(data.backgroundColor)

    return (
        <Box py={5} bgcolor={bgcolor}>
            <Container maxWidth="lg">
                {hasTitle && (
                    <Typography className={classes.title}>
                        {data.title}
                    </Typography>
                )}
                {hasSubtitle && <Typography>{data.subtitle}</Typography>}
                {hasText && (
                    <Typography className={classes.text}>
                        {renderRichText(data.text, options())}
                    </Typography>
                )}
                {hasImageText &&
                    data.imageText.map((input) => {
                        const hasImage = Boolean(input.image)
                        const hasText = Boolean(input.text)

                        const whatImage = (input) => {
                            return input === 'image/svg+xml' ? 'svg' : 'png'
                        }

                        const whatFormat = whatImage(
                            input.image.file.contentType
                        )

                        const images = getImage(input.image)

                        return (
                            <Box py={3}>
                                <Grid container alignItems="center">
                                    <Grid item xs={3} md={1}>
                                        {hasImage && whatFormat === 'png' && (
                                            <GatsbyImage
                                                className={classes.image}
                                                image={images}
                                                alt={input.image.title}
                                            />
                                        )}
                                        {hasImage && whatFormat === 'svg' && (
                                            <Box className={classes.imageBox}>
                                                <img
                                                    src={input.image.file.url}
                                                />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid xs={9} md={10}>
                                        {hasText && (
                                            <Typography
                                                className={
                                                    classes.secondaryTexts
                                                }
                                            >
                                                {renderRichText(
                                                    input.text,
                                                    options()
                                                )}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    })}
            </Container>
        </Box>
    )
}

export default RichText
